import { Outlet } from 'react-router-dom';

import { Box, Divider, CssBaseline, Container } from '@mui/material';

// project imports
import Header from './Header';
import Footer from './Footer';
// import Customization from 'layout/Customization';

// interface MainStyleProps {
//     theme: Theme;
//     open?: boolean;
//     layout?: string;
// }

// styles
// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, layout }: MainStyleProps) => ({
//     ...theme.typography.mainContent,
//     background: theme.palette.background.default,
//     borderRadius: 0,
//     marginRight: 0
// }));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    // const theme = useTheme();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <CssBaseline />
            {/* header */}
            {/* <AppBar enableColorOnDark position="fixed" color="inherit" elevation={0} sx={{ zIndex: 99999 }}>
        <Toolbar>
            <Header />
        </Toolbar>
    </AppBar> */}

            {/* main content */}
            {/* <Main theme={theme}> */}
            {/* breadcrumb */}
            <Box sx={{ width: '100%' }}>
                <Header />
            </Box>
            {/* <Container sx={{ marginTop: '200px', width: '100%' }}> */}
            {/* <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign /> */}
            <Outlet />
            <Divider />
            <Container>
                <Footer />
            </Container>
            {/* </Container> */}
            {/* </Main> */}
            {/* <Customization /> */}
        </Box>
    );
};

export default MainLayout;

import { Box, Tooltip } from '@mui/material';
import {useIntl} from 'react-intl'

const MONITOR_STATUS_URL = process.env.REACT_APP_MONITOR_STATUS_URL


const MonitorButton = () => {
  const intl = useIntl()
  return (
    <Tooltip title={intl.formatMessage({ id: 'all-services-are-online'})} arrow>
    <Box
      onClick={() => window.open(`${MONITOR_STATUS_URL}`)}
      sx={{

        position: 'relative',
        width: '14px',
        height: '14px',
        borderRadius: '50%',
        backgroundColor: '#80ed99',
        '&:hover': {
          cursor: 'pointer'
        },
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '14px',
          height: '14px',
          borderRadius: '50%',
          backgroundColor: '#80ed99',
        },
        '&::after': {
          animation: 'pulse 2s infinite ease-in-out',
        },
        '@keyframes pulse': {
          from: {
            transform: 'scale(0.7)',
            opacity: 1,
          },
          to: {
            transform: 'scale(1.8)',
            opacity: 0,
          },
        },
      }}
    />
   </Tooltip>
  );
};

export default MonitorButton;


import { Button } from '@mui/material';
import React, { useState } from 'react';
import { ButtonProps } from 'types/section';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

export interface ButtonComponentProps {
    button: ButtonProps;
    icon?: React.ReactNode;
    variant?: 'text' | 'outlined' | 'contained';
}

const ButtonComponent: React.FC<ButtonComponentProps> = ({ button, icon, variant }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [hover, setHover] = useState(false);

    const handleClick = () => {
        if (button.isNavigate === false) {
            window.open(button.link, '_blank');
        } else {
            navigate(button.link);
        }
    };

    return (
        <Button
            sx={{
                backgroundColor: theme.palette.mode === 'dark' ? '#ffffff00' : '#3654ff',
                color: '#fff',
                border: ' 1px solid #3654ff',
                borderRadius: '11px',
                textAlign: 'center',
                transition: 'all 0.6s ease',
                padding: '5px 10px',
                '&:hover': {
                    backgroundColor: '#3654ff',
                    cursor: 'pointer'
                },
                '& .MuiButton-endIcon': {
                    transition: 'transform 0.3s ease',
                    transform: hover ? 'rotate(0deg)' : 'rotate(180deg)'
                }
            }}
            onClick={handleClick}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            endIcon={hover ? <ArrowOutwardIcon fontSize="small" /> : <ArrowBackIcon fontSize="small" />}
        >
            {button.name}
        </Button>
    );
};

export default ButtonComponent;

// material-ui
// import { useTheme } from '@mui/material/styles';

import { PaletteMode, RadioGroup, FormControlLabel, Radio, Chip, Avatar } from '@mui/material';
// import FormControl from '@mui/material/FormControl';

// project-imports
import useConfig from 'hooks/useConfig';

// assets
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

// ==============================|| CUSTOMIZATION - MODE ||============================== //

const ThemeModeLayout = () => {
    // const theme = useTheme();
    const { navType, onChangeMenuType } = useConfig();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChangeMenuType(e.target.value as PaletteMode);
    };

    // const handleModeClick = () => {
    //     onChangeMenuType(navType);
    // };

    return (
        // <FormControl sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <RadioGroup row aria-label="layout" value={navType} onChange={handleChange} name="row-radio-buttons-group">
                <FormControlLabel
                    value={navType === 'light' ? 'dark' : 'light'}
                    control={<Radio sx={{ display: 'none' }} />}
                    label={
                        <Chip
                            label={navType === 'light' ? 'Dark' : 'Light'}
                            avatar={
                                <Avatar>
                                    {navType === 'light' ? (
                                        <DarkModeIcon fontSize="small" color="primary" />
                                    ) : (
                                        <LightModeIcon fontSize="small" color="primary" />
                                    )}
                                </Avatar>
                            }
                        />
                    }
                />
            </RadioGroup>
        // </FormControl>
    );
};

export default ThemeModeLayout;
